'use strict';

window.apiUri = location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '');


// Load controllers
require('./controllers/LoginCtrl');
require('./controllers/DashboardCtrl');
require('./controllers/OverviewCtrl');
require('./controllers/DonateCtrl');
require('./controllers/CharacterCtrl');
require('./controllers/SerialChangeCtrl');
require('./controllers/NameChangeCtrl');
require('./controllers/AdminCtrl');
require('./controllers/AdminUserCtrl');
require('./controllers/AdminCharacterCtrl');
require('./controllers/AdminStatisticsCtrl');
require('./controllers/AdminBanCtrl');
require('./controllers/AdminUnbanCtrl');

// Declare app level module which depends on views, and components
angular.module('mta', [
    'ui.router',
    'angularMoment',
    'angular-content-editable',
    'angularSpinner',
    'ngCookies',
    'ui.footable',
    'ui.bootstrap',
    'ui.bootstrap.datetimepicker',

    // controllers
    'mta.login',
    'mta.dashboard',
    'mta.donate',
    'mta.overview',
    'mta.character',
    'mta.serialchange',
    'mta.namechange',
    'mta.admin',
    'mta.admin.user',
    'mta.admin.character',
    'mta.admin.ban',
    'mta.admin.unban',
    'mta.admin.statistics',
])
.config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.otherwise('/');
}])
.run(['$state', '$rootScope', '$location', function($state, $rootScope, $location){

    if($location.path() != '/') {
        $location.path('/');
    }

    if(!$rootScope.userId) $state.go('login');
}]);

// Load services
require('./services/$api');
require('./services/AuthSrvc');
require('./services/UserSrvc');
require('./services/SerialSrvc');
require('./services/NameSrvc');
require('./services/CharSrvc');
require('./services/BanSrvc');
require('./services/StatsSrvc');
require('./services/PaymentSrvc');

// Load filters
require('./directives/huPhone');